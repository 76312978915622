<template>
  <li
    v-if="!form.startDate || !isCampaignEnded"
    class="Action"
  >
    <div class="ActionWrapper">
      <a
        :href="form.url"
        target="_blank"
        rel="noreferrer noopener"
        :class="`ActionLink ActionLink-${form.formType}`"
        :data-ux="`Explore_OrganizationPublicPage_Actions_Action${
          form.formType
        }${index + 1}`"
        @click.prevent="trackCall"
      >
        <div class="ActionImage">
          <img
            v-if="banner"
            :src="
              resizedSrc(banner, {
                width: 220,
                height: 220
              })
            "
            :alt="$t('organization.actions.action.bannerAlt')"
            class="ActionImage--Banner"
          />
          <img
            v-else
            :src="`https://cdn.helloasso.com/images/explore/illustrations/placeholders/typology/placeholder-typology-${form.formType.toLowerCase()}.png`"
            :alt="
              $t(
                `organization.actions.action.group.${form.formType.toLowerCase()}.name`
              )
            "
            :class="`ActionImage--Tool ActionImage--Tool-${form.formType}`"
          />
        </div>
        <div class="ActionContent">
          <div class="ActionContent--Text">
            <h3>{{ cleanFormTitle }}</h3>
            <p v-if="form.description">
              {{ form.description }}
            </p>
          </div>
          <div class="ActionContent--Numbers">
            <!-- Date & fourchette de prix -->
            <template v-if="dateLabel">
              <p class="Number Number-Date">
                <HaIcon :icon="faCalendar" />
                {{ dateLabel }}
              </p>
            </template>
            <template v-if="isMinPriceDefined">
              <p class="Number Number-BasePrice">
                <HaIcon :icon="faCoins" />
                {{ priceLabel }}
              </p>
            </template>
          </div>
        </div>
        <div
          :class="[
            'ActionDetails',
            { 'ActionDetails-NoDetails': !hasDetails }
          ]"
        >
          <div
            v-if="form.place"
            class="ActionDetails--Data"
          >
            <template v-if="doesPlaceContainUrl">
              {{ $t(`organization.actions.action.OnlineEvent`) }}
            </template>
            <template v-else>
              <p
                v-if="form.place.name"
                class="Data Data-AddressName"
              >
                {{ form.place.name }}
              </p>
              <p
                v-if="form.place.address"
                class="Data Data-Address"
              >
                {{ form.place.address }}
              </p>
              <p
                v-if="form.place.zipCode || form.place.city"
                class="Data Data-City"
              >
                <span v-if="form.place.zipCode">{{
                  form.place.zipCode
                }}</span>
                <span v-if="form.place.city">{{
                  form.place.city
                }}</span>
              </p>
              <p
                v-if="
                  form.place.country &&
                  form.place.country !== 'FRA' &&
                  form.place.country !== 'France'
                "
                class="Data Data-Country"
              >
                {{ form.place.country }}
              </p>
            </template>
          </div>
          <div
            v-if="form.membershipPeriod"
            class="ActionDetails--Data"
          >
            <p class="Data Data-Membership">
              {{ form.membershipPeriod }}
            </p>
          </div>
          <span class="ActionDetails--FakeCta">
            {{
              $t(
                `organization.actions.action.group.${form.formType.toLowerCase()}.cta`
              )
            }}
            <HaIcon :icon="faChevronRight" />
          </span>
        </div>
        <span class="visually-hidden">
          {{
            $t(
              `organization.actions.action.group.${form.formType.toLowerCase()}.label`,
              [cleanFormTitle, organization.name]
            )
          }}
        </span>
        <span class="visually-hidden">
          {{ $t('accessibility.openNewWindow') }}
        </span>
      </a>
      <!-- Edit & Share -->
      <template v-if="editMode">
        <a
          :href="adminUrl"
          target="_blank"
          rel="noreferrer noopener"
          class="ActionEdit"
          :data-ux="`Explore_OrganizationPublicPage_EditMode_Actions_Action${
            form.formType
          }${index + 1}_Edit`"
        >
          <HaIcon :icon="faPen" />
          <span class="visually-hidden">
            {{
              $t('organization.actions.action.editLabel', [
                cleanFormTitle
              ])
            }}
          </span>
          <span class="visually-hidden">
            {{ $t('accessibility.openNewWindow') }}
          </span>
        </a>
      </template>
      <template v-else>
        <ha-dropdown>
          <template #trigger>
            <button
              class="ActionShare"
              :data-ux="`Explore_OrganizationPublicPage_Actions_Action${
                form.formType
              }${index + 1}_Toggle_ShareLinks`"
            >
              <HaIcon :icon="faShareNodes" />
              <span class="visually-hidden">
                {{ $t('organization.actions.action.shareLabel') }}
              </span>
              <span class="visually-hidden">
                {{ $t('accessibility.openNewWindow') }}
              </span>
            </button>
          </template>
          <template #content>
            <ha-menu-list>
              <ha-menu-list-item
                v-for="(item, i) in items"
                :key="`brand-${i}`"
                :class="`HaMenuListItem-${
                  item.name.charAt(0).toUpperCase() +
                  item.name.slice(1)
                }`"
                :icon="item.icon"
                :label="item.label"
                :data-ux="item.dataUx"
                @click="openSharePopup(item.href)"
              />
              <ha-menu-list-item
                class="HaMenuListItem-Email"
                :icon="faEnvelope"
                :label="
                  $t(
                    'organization.actions.action.share.email.label'
                  )
                "
                :href="`mailto:?to=&subject=${cleanFormTitle}&body=${$t(
                  'organization.actions.action.share.email.body',
                  [organization.name, form.url]
                )}`"
                :data-ux="`Explore_OrganizationPublicPage_Actions_Action${form.formType}_ShareByEmail`"
                rel="nofollow"
              />
              <ha-menu-list-item
                class="HaMenuListItem-CopyUrl"
                :icon="faLink"
                :label="
                  $t(
                    'organization.actions.action.share.copyUrl.label'
                  )
                "
                :data-ux="`Explore_OrganizationPublicPage_Actions_Action${form.formType}_CopyUrl`"
                @click="copyUrl(form.url)"
              />
            </ha-menu-list>
          </template>
        </ha-dropdown>
      </template>
    </div>
  </li>
</template>

<script>
import { get } from 'lodash-es'
import {
  faCalendar,
  faCoins,
  faChevronRight,
  faPen,
  faShareNodes,
  faEnvelope,
  faLink
} from '@fortawesome/pro-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons'
import {
  HaDropdown,
  HaMenuList,
  HaMenuListItem,
  HaIcon,
  useNotifications,  
  useTracking
} from '@ha/components-v3'
import { enum as enums, haMoment } from '@ha/helpers'
import { resizedSrc, amountToLocaleFormattedEuros } from '@/helpers/utils'
import { buildCardInfo } from '@/helpers'
import useConfigEnv from '@/composables/useConfigEnv'
import useEditMode from '@/composables/useEditMode'
import useLocale from '@/composables/useLocale'
import { useI18n, computed } from '#imports'

export default {
  name: 'Action',
  components: {
    HaDropdown,
    HaMenuList,
    HaMenuListItem,
    HaIcon
  },
  props: {
    form: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const configEnv = useConfigEnv()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    const locale = useLocale()

    const priceLabel = computed(() => {
      const minPriceFormatted = amountToLocaleFormattedEuros(
        {
          amount: props.form.minPrice,
          areCents: true
        },
        locale
      )
      if (props.form.minPrice === props.form.maxPrice) {
        if (props.form.minPrice === 0) {
          return i18n.t('organization.actions.action.free')
        }
        return minPriceFormatted
      } else if (!props.form.maxPrice) {
        if (props.form.minPrice <= 50) {
          return i18n.t('organization.actions.action.customPrice')
        }
        if (props.form.minPrice > 50) {
          return i18n.t('organization.actions.action.priceFrom', [
            minPriceFormatted
          ])
        }
      }
      return i18n.t('organization.actions.action.startingFromTo', {
        from: minPriceFormatted,
        to: amountToLocaleFormattedEuros(
          {
            amount: props.form.maxPrice,
            areCents: true
          },
          locale
        )
      })
    })

    return {
      tracking,
      pushNotification,
      configEnv,
      editMode,
      i18n,
      priceLabel,
      faCalendar,
      faCoins,
      faChevronRight,
      faPen,
      faShareNodes,
      faEnvelope,
      faLink
    }
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization
    },
    formTypes() {
      return enums.FormType
    },
    adminUrl() {
      switch (this.form.formType) {
        case this.formTypes.EVENT:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/evenements/${this.form.formSlug}/edition/1`
        case this.formTypes.SHOP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/boutiques/${this.form.formSlug}/edition/1`
        case this.formTypes.MEMBERSHIP:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/adhesions/${this.form.formSlug}/edition/1`
        case this.formTypes.CROWDFUNDING:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/collectes/${this.form.formSlug}/edition/1`
        case this.formTypes.DONATION:
          return `${this.configEnv.NUXT_ENV_BO_URL}/${this.organization.organizationSlug}/formulaires/${this.form.formSlug}/edition/1`
        case this.formTypes.PAYMENTFORM:
          return `${this.configEnv.NUXT_ENV_OLD_BO_URL}/associations/${this.organization.organizationSlug}/paiements/${this.form.formSlug}/modifier`
        default:
          return ''
      }
    },
    banner() {
      return (
        get(this.form, 'logo.publicUrl') ||
        get(this.form, 'banner.publicUrl')
      )
    },
    cleanFormTitle() {
      return get(this.form, 'title', '')
        .replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
        .replace(/&nbsp;/g, ' ')
    },
    isCampaignEnded() {
      return get(this.form, 'endDate')
        ? new Date(this.form.endDate).getTime() < Date.now()
        : false
    },
    items() {
      return [
        {
          name: 'facebook',
          icon: faFacebookF,
          label: this.$t(
            'organization.actions.action.share.facebook.label'
          ),
          href: `https://www.facebook.com/sharer/sharer.php?u=${this.form.url}`,
          dataUx: `Explore_OrganizationPublicPage_Actions_Action${
            this.form.formType
          }${this.index + 1}_ShareOnFacebook`
        },
        {
          name: 'twitter',
          icon: faTwitter,
          label: this.$t(
            'organization.actions.action.share.twitter.label'
          ),
          href: `https://twitter.com/intent/tweet?text=${this.$t(
            'organization.actions.action.share.twitter.text',
            [this.cleanFormTitle, this.form.url]
          )}`,
          dataUx: `Explore_OrganizationPublicPage_Actions_Action${
            this.form.formType
          }${this.index + 1}_ShareOnTwitter`
        },
        {
          name: 'linkedin',
          icon: faLinkedin,
          label: this.$t(
            'organization.actions.action.share.linkedin.label'
          ),
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${this.form.url}&title=${this.cleanFormTitle}&source=HelloAsso`,
          dataUx: `Explore_OrganizationPublicPage_Actions_Action${
            this.form.formType
          }${this.index + 1}_ShareOnLinkedin`
        }
      ]
    },
    hasDetails() {
      return !!(get(this.form, 'place') || this.form.membershipPeriod)
    },
    dateLabel() {
      return this.form
        ? this.getDateLabel(
            get(this.form, 'startDate'),
            get(this.form, 'endDate')
          )
        : ''
    },
    isMinPriceDefined() {
      return typeof this.form.minPrice !== 'undefined'
    },
    doesPlaceContainUrl() {
      const place = get(this.form, 'place')

      if (!place) {
        return false
      }

      const { address, country, name, zipcode, city } = place

      const pattern = /^(https?:\/\/)/im

      return (
        pattern.test(address) ||
        pattern.test(country) ||
        pattern.test(city) ||
        pattern.test(zipcode) ||
        pattern.test(name)
      )
    }
  },
  methods: {
    copyUrl(href) {
      navigator.clipboard.writeText(href)
      this.pushNotification({
        type: 'success',
        title: this.$t('toast.copyUrl.title'),
        body: this.$t('toast.copyUrl.body'),
        timeout: 5000
      })
    },
    openSharePopup(href) {
      const popupWidth = 560
      const popupHeight = 480
      const popupXPosition =
        window.screen.availLeft +
        (window.screen.availWidth - popupWidth) / 2
      const popupYPosition =
        window.screen.availTop +
        (window.screen.availHeight - popupHeight) / 2

      window.open(
        href,
        'Share',
        `width=${popupWidth},height=${popupHeight},left=${popupXPosition},top=${popupYPosition}`
      )
    },
    percentage(amount, total) {
      if (amount > total) {
        amount = total
      }
      return (amount / total) * 100
    },
    getDateLabel(startDate, endDate) {
      // If missing date
      // > no message is displayed under the ticketing title of the purchase path
      if (!startDate || !endDate) {
        return null
      }

      const isSameDay = haMoment(startDate).isSame(endDate, 'day')

      // is the same day
      if (isSameDay) {
        return this.$t('date.SameDay.withNoTime', [
          haMoment(endDate).format('DD/MM/YYYY')
        ])
      }

      // both dates defined and not the same day
      const startDateLabel = haMoment(startDate).format('DD/MM/YYYY')
      const endDateLabel = haMoment(endDate).format('DD/MM/YYYY')

      return this.$t('date.with2Dates.withNoTime', [
        startDateLabel,
        endDateLabel
      ])
    },
    trackCall() {
      const trackInfo = buildCardInfo(
        this.form,
        this.index,
        'Carrousel'
      )

      this.tracking.track('Explore Card Clicked', trackInfo)
      window.open(this.form.url, '_blank', 'noopener noreferrer')
    },
    resizedSrc(...arg) {
      return resizedSrc(...arg)
    }
  }
}
</script>

<style lang="scss">
@import './styles/action';
</style>
