<template>
  <section
    id="headline"
    class="Organization--Headline Is-Paddingless"
  >
    <Banner />
    <div class="Intro">
      <div class="Container">
        <div class="Intro--Logo">
          <Logo />
        </div>
        <h1 class="Intro--Name">
          {{ organization.name }}
        </h1>
        <template v-if="!editMode">
          <span
            v-if="organization.category"
            class="Intro--Category"
            data-test-id="IntroCategory"
          >
            <HaIcon :icon="faTag" />
            {{ organization.category }}
          </span>
          <p
            v-if="organization.description"
            class="Intro--ShortDescription"
          >
            {{ organization.description }}
          </p>
        </template>
        <HaValidatorForm
          v-else
          v-slot="{ invalid }"
          ref="category"
        >
          <div class="Intro--CategoryEdition">
            <HaValidatorInput
              v-slot="{ validated, valid, errors }"
              slim
              :model-value="categoryModel"
              name="category"
              rules="required"
            >
              <ha-form-field :is-valid="validated ? valid : null">
                <template #label>
                  <span class="visually-hidden">
                    {{
                      $t('organization.headline.categoryLabel')
                    }}
                  </span>
                </template>
                <ha-select
                  v-model="categoryModel"
                  :options="sortedCategories"
                  :is-valid="validated ? valid : null"
                  name="category"
                />
                <template #error>
                  {{ errors[0] }}
                </template>
              </ha-form-field>
            </HaValidatorInput>
          </div>
          <div class="Intro--ShortDescriptionEdition">
            <ha-form-field>
              <template #label>
                {{
                  $t(
                    'organization.headline.shortDescriptionLabel'
                  )
                }}
              </template>
              <HaTextArea
                v-model="descriptionModel"
                name="short_description"
                :max-length="300"
                :is-valid="undefined"
                :rows="6"
                :placeholder="
                  $t(
                    'organization.headline.shortDescriptionPlaceholder'
                  )
                "
              />
              <ha-button
                :loading="isLoading"
                :disabled="invalid || isLoading"
                data-ux="Explore_OrganizationPublicPage_EditMode_Headline_SaveShortDescription"
                @click="save('shortDescription')"
              >
                {{ $t('button.save') }}
              </ha-button>
            </ha-form-field>
          </div>
        </HaValidatorForm>
      </div>
    </div>
    <ClientOnly>
      <Navigation />
    </ClientOnly>
  </section>
</template>

<script>
import { find, forEach, sortBy, get } from 'lodash-es'
import { faTag } from '@fortawesome/pro-solid-svg-icons'
import { functions } from '@ha/helpers'
import {
  HaFormField,
  HaSelect,
  HaTextArea,
  HaButton,  
  useNotifications,
  HaIcon,
  HaValidatorInput,
  HaValidatorForm
} from '@ha/components-v3'
import useSegment from '@/composables/useSegment'

import Banner from '@/components/Organization/Partials/Banner.vue'
import Logo from '@/components/Organization/Partials/Logo.vue'
import Navigation from '@/components/Organization/Partials/Navigation.vue'
import useEditMode from '@/composables/useEditMode'
import { useI18n }from '#imports'

export default {
  name: 'Headline',
  components: {
    Banner,
    Logo,
    Navigation,
    HaFormField,
    HaSelect,
    HaTextArea,
    HaButton,
    HaValidatorInput,
    HaValidatorForm,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { trackUpdateOrganization } = useSegment()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    return {
      pushNotification,
      trackUpdateOrganization,
      editMode,
      i18n
    }
  },
  data() {
    return {
      isLoading: false,
      displayModalUpload: false,
      categoryModel:
        this.$store.state.organizations.organization.category ?? null,
      descriptionModel: '',
      faTag
    }
  },
  computed: {
    organization: {
      get() {
        return this.$store.state.organizations.organization
      }
    },
    types() {
      return this.$store.state.organizations.types
    },
    sortedCategories() {
      const categories = []

      forEach(this.types, (category) => {
        categories.push({
          id: category.id,
          text: category.shortLabel,
          value: category.shortLabel,
          key: functions.deburr(category.shortLabel)
        })
      })
      return sortBy(categories, ['key'])
    }
  },
  watch: {
    organization: {
      deep: true,
      handler() {
        this.categoryModel = this.organization.category
        this.descriptionModel = this.organization.description
      }
    }
  },
  mounted() {
    // in case of empty category, user is not allowed to save an incorrect form
    // validating at mounted step, displays the message to help him understanding why

    if (this.$refs.category) {
      this.$refs.category.validate()
    }

    if (this.organization.description) {
      this.descriptionModel = this.organization.description
    }
  },
  methods: {
    save(type) {
      this.isLoading = true

      const category = find(this.sortedCategories, {
        value: this.categoryModel
      })
      // TODO refacto; this payload is in all Organization components
      const payload = {
        address: this.organization.address,
        city: this.organization.city,
        description: this.descriptionModel,
        displayCoordinates: this.organization.displayCoordinates,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        idJoCategory: get(category, 'id', null),
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }
      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )

      this.$store
        .dispatch('organizations/saveOrganizationData', {
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t(`toast.${type}Update.title`),
            body: this.$t(`toast.${type}Update.body`),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error.response.status}.message`
            ),
            timeout: 5000
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import './styles/headline';
</style>
