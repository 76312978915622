<template>
  <div :class="{ EditMode: editMode }">
    <ClientOnly>
      <BannerEditMode v-if="isAdmin" />
    </ClientOnly>
    <Headline />
    <Actions />
    <About />
    <ClientOnly>
      <Share />
      <Carousel />
      <Contact />
      <BeVolunteer/>
    </ClientOnly>
    <Report />
    <ScrollToTop />
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import {
  useServerSeoMeta,
  useRuntimeConfig,
  computed,
  useRoute,
  useHead,
  useI18n
} from '#imports'
import useEditMode from '@/composables/useEditMode'

import BannerEditMode from '@/components/Banner/BannerEditMode.vue'
import Headline from '@/components/Organization/Headline.vue'
import Actions from '@/components/Organization/Actions.vue'
import About from '@/components/Organization/About.vue'
import Share from '@/components/Organization/Share.vue'
import Contact from '@/components/Organization/Contact.vue'
import Carousel from '@/components/Organization/Carousel.vue'
import Report from '@/components/Organization/Report.vue'
import ScrollToTop from '@/components/ScrollToTop/ScrollToTop.vue'
import BeVolunteer from '@/components/Organization/BeVolunteer.vue'

const config = useRuntimeConfig()
const i18n = useI18n()
const store = useStore()
const route = useRoute()
const { editMode } = useEditMode()

const organization = computed(() => {
  return store.state.organizations.organization
})

const isAdmin = computed(() => {
  return store.getters['user/isAdmin'](route.params.organization)
})

const pageTitle = computed(() => {
  return editMode.value
    ? `${i18n.t('organization.pageTitle')} ${
        organization.value.name
      } | HelloAsso`
    : `${organization.value.name} | HelloAsso`
})

const openGraphImageSharing = (fallback) => {
  if (organization.value.banner) {
    return organization.value.banner
  }
  return organization.value.logo ? organization.value.logo : fallback
}

useServerSeoMeta({
  title: pageTitle.value,
})

useHead({
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: organization.value.description
    },
    {
      hid: 'og:description',
      name: 'og:description',
      content: organization.value.description
    },
    {
      hid: 'og:title',
      name: 'og:title',
      content: `${organization.value.name} | HelloAsso`
    },
    {
      hid: 'og:url',
      name: 'og:url',
      content: `${config.public.NUXT_ENV_BASE_URL}/associations/${organization.value.organizationSlug}`
    },
    {
      hid: 'og:image',
      name: 'og:image',
      content: openGraphImageSharing(
    `${config.public.NUXT_ENV_BASE_URL}/explore/social-default.jpg`
  )
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: openGraphImageSharing(
    `${config.public.NUXT_ENV_BASE_URL}/explore/social-default.jpg`
  )
    }
  ],
  link: [
    {
      rel: 'canonical',
      href: `${config.public.NUXT_ENV_BASE_URL}/associations/${organization.value.organizationSlug}`
    }
  ]
})
</script>

<style lang="scss">
@import './styles/organization';
</style>
