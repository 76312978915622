<template>
  <div class="Socials">
    <h4>{{ $t('organization.contact.socials.title') }}</h4>
    <ul class="SocialList">
      <SocialNetwork
        v-for="network in socialNetwork"
        :key="network"
        :type="network"
      ></SocialNetwork>
    </ul>
  </div>
</template>

<script>
import SocialNetwork from '@/components/Organization/Partials/SocialNetwork.vue'
import { useI18n } from '#imports'

export default {
  name: 'ContactSocials',
  components: {
    SocialNetwork
  },
  setup() {
    const i18n = useI18n()

    return {
      i18n
    }
  },
  data() {
    return {
      isUpdated: false,
      socialNetwork: [
        'webSite',
        'facebookPage',
        'twitterPage'
        // 'instagramPage',
        // 'youtubePage'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/socials';
</style>
