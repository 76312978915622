<template>
  <section
    v-if="editMode || organization.displayCoordinates || hasSocials"
    id="contact"
    class="Organization--Contact"
  >
    <div class="SectionHeader">
      <div class="Container">
        <span class="SectionHeader--Icon">
          <HaIcon :icon="faLocationDot" />
        </span>
        <h2 data-test-id="ContactSection">
          <span>{{ $t('organization.contact.title_01') }}</span>
          {{ $t('organization.contact.title_02') }}
        </h2>
      </div>
    </div>
    <div class="Map">
      <div class="Map--Image">
        <HAMap
          v-if="
            hasCoordinates &&
            organization.displayCoordinates &&
            loadMap
          "
          :coordinates="coordinates"
        />
      </div>
      <div class="Map--Details">
        <Infos
          v-if="editMode || organization.displayCoordinates"
          @change-display-coordinates="displayCoordinates($event)"
        />
        <ContactSocials v-if="editMode || hasSocials"></ContactSocials>
      </div>
    </div>
  </section>
</template>

<script>
import { get } from 'lodash-es'
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons'
import HAMap from '@/components/Organization/Partials/Map.vue'
import Infos from '@/components/Organization/Partials/Infos.vue'
import ContactSocials from '~/components/Organization/Partials/ContactSocials.vue'
import { useNotifications, HaIcon } from '@ha/components-v3'
import useSegment from '@/composables/useSegment'
import useEditMode from '@/composables/useEditMode'
import { useI18n } from '#imports'

export default {
  name: 'Contact',
  components: {
    HAMap,
    Infos,
    ContactSocials,
    HaIcon
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { trackUpdateOrganization } = useSegment()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    return {
      pushNotification,
      trackUpdateOrganization,
      editMode,
      i18n
    }
  },
  data() {
    return {
      loadMap: false,
      faLocationDot
    }
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization
    },
    hasCoordinates() {
      return !!get(this.organization, 'geolocation')
    },
    coordinates() {
      const geoLocation = get(this.organization, 'geolocation')
      const { latitude, longitude } = geoLocation

      return [latitude, longitude]
    },
    hasSocials() {
      return (
        this.organization?.webSite ||
        this.organization?.facebookPage ||
        this.organization?.twitterPage ||
        this.organization?.instagramPage ||
        this.organization?.youtubePage ||
        false
      )
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    displayCoordinates(value) {
      const payload = {
        address: this.organization.address,
        category: this.organization.category,
        city: this.organization.city,
        description: this.organization.description,
        displayCoordinates: value,
        email: this.organization.email,
        facebookPage: this.organization.facebookPage,
        geoLocation: this.organization.geoLocation,
        instagramPage: this.organization.instagramPage,
        longDescription: this.organization.longDescription,
        phone: this.organization.phone,
        twitterPage: this.organization.twitterPage,
        webSite: this.organization.webSite,
        youtubePage: this.organization.youtubePage,
        zipCode: this.organization.zipCode
      }

      this.trackUpdateOrganization(
        this.organization.organizationSlug,
        this.organization,
        payload
      )

      this.$store
        .dispatch('organizations/saveOrganizationData', {
          slug: this.organization.organizationSlug,
          payload
        })
        .then(() => {
          this.pushNotification({
            type: 'success',
            title: this.$t('toast.displayCoordinates.title'),
            body: value
              ? this.$t('toast.displayCoordinates.bodyTrue')
              : this.$t('toast.displayCoordinates.bodyFalse'),
            timeout: 5000
          })
        })
        .catch((error) => {
          this.pushNotification({
            type: 'danger',
            title: this.$t(
              `error.code.${error.response.status}.title`
            ),
            body: this.$t(
              `error.code.${error.response.status}.message`
            ),
            timeout: 5000
          })
        })
    },
    handleScroll() {
      if (import.meta.client) {
        const windowHeight = window.innerHeight
        const map = document
          .querySelector('#contact')
          ?.getBoundingClientRect()
        const mapY = map?.top || 0

        // Load map only when reaching Contact section, minus window height
        if (mapY - windowHeight <= 0 && this.loadMap === false) {
          this.loadMap = true
          window.removeEventListener('scroll', this.handleScroll)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles/contact';
</style>

<style lang="scss" scoped>
/**
 *  Edit Mode
 */

.EditMode {
  .Organization--Contact {
    @include mediaQuery(900) {
      padding-bottom: calc(
        #{$ha-unit * 12} + #{$ha-unit * 5}
      ); // 96px + (64px - 24px) => 136px
    }
  }

  .Map {
    &--Details {
      @include mediaQuery(900) {
        width: $ha-unit * 60; // 480px
        max-height: calc(100% + #{$ha-unit * 12}); // 96px
        overflow-y: auto;
      }
    }
  }
}
</style>
