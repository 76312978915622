<template>
  <ha-modal
    class="ModalReport"
    close-id="Explore_Modal_Report_Close"
    v-bind="$attrs"
    @close="emit('close')"
  >
    <template #header>
      {{ $t('modal.report.title') }}
    </template>
    <template #body>
      <form @submit.prevent="submitReport">
        <div class="modal-body-wrapper">
          <fieldset class="FormGroup">
            <legend>
              {{ $t('modal.report.why') }}
            </legend>
            <ha-radio
              v-for="reason in reportReasons"
              :key="reason"
              v-model="option"
              name="report"
              :data-ux="`Explore_OrganizationPublicPage_Modal_Report_Reason_${reason}`"
              :value="reason"
              required="true"
            >
              {{ $t(`modal.report.reasons.${reason}`) }}
            </ha-radio>
            <ha-error>
              {{ $t('modal.report.reasonRequired') }}
            </ha-error>
          </fieldset>
          <ha-form-group>
            <template #title="slotProps">
              <ha-label v-bind="slotProps">
                {{ $t('modal.report.emailInput.label') }} *
              </ha-label>
            </template>
            <template #default="slotProps">
              <HaInput
                v-bind="slotProps"
                id="email"
                v-model="email"
                type="email"
                name="email"
                :placeholder="
                  $t('modal.report.emailInput.placeholder')
                "
                required="true"
              />
              <ha-error>
                {{ $t('modal.report.emailInput.error') }}
              </ha-error>
            </template>
          </ha-form-group>
          <ha-form-group>
            <template #title="slotProps">
              <ha-label v-bind="slotProps">
                {{ $t('modal.report.messageInput.label') }} *
              </ha-label>
            </template>
            <template #default="slotProps">
              <ha-text-area
                v-bind="slotProps"
                id="message"
                v-model="message"
                name="message"
                rows="5"
                :placeholder="
                  $t('modal.report.messageInput.placeholder')
                "
                required="true"
                minlength="1"
              />
              <ha-error>
                {{ $t('modal.report.messageInput.error') }}
              </ha-error>
            </template>
          </ha-form-group>
          <div class="form-buttons">
            <ha-button
              color="basic"
              variant="flat"
              data-ux="Explore_OrganizationPublicPage_Modal_Report_Cancel"
              type="button"
              @click="emit('close')"
            >
              {{ $t('button.cancel') }}
            </ha-button>
            <ha-button
              :loading="isLoading"
              class="submit-button"
              type="submit"
              data-ux="Explore_OrganizationPublicPage_Modal_Report_Send"
            >
              {{ $t('button.send') }}
            </ha-button>
          </div>
        </div>
      </form>
    </template>
  </ha-modal>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { ref, onMounted, useNuxtApp, useI18n } from '#imports'
import {
  HaModal,
  HaFormGroup,
  HaLabel,
  HaInput,
  HaRadio,
  HaTextArea,
  HaError,
  HaButton,
  useNotifications
} from '@ha/components-v3'

const i18n = useI18n()
const store = useStore()
// TODO - quick fix ts
const nuxtApp: any = useNuxtApp()

const reportReasons = {
  SPAM: 'SPAM',
  DISLIKE: 'DISLIKE',
  OFF_TOPIC: 'OFF_TOPIC',
  INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY'
}

const emit = defineEmits(['close'])

const { pushNotification } = useNotifications()

const option = ref('')
const email = ref('')
const message = ref('')
const isLoading = ref(false)

onMounted(async () => {
  await nuxtApp.$recaptcha.init()
})

const submitReport = async () => {
  const token = await nuxtApp.$recaptcha.execute('login')

  if (token) {
    isLoading.value = true

    const reportData = {
      reason: option.value,
      userEmail: email.value,
      userMessage: message.value,
      url: typeof window !== 'undefined' ? window.location.href : ''
    }

    store
      .dispatch('forms/postReport', reportData)
      .then(() => {
        pushNotification({
          type: 'success',
          title: i18n.t('modal.report.successToast.title'),
          body: i18n.t('modal.report.successToast.body'),
          timeout: 5000
        })
      })
      .catch(() => {
        pushNotification({
          type: 'danger',
          title: i18n.t('modal.report.errorToast.title'),
          body: i18n.t('modal.report.errorToast.title'),
          timeout: 5000
        })
      })
      .finally(() => {
        isLoading.value = false
        emit('close')
      })
  }
}
</script>

<style lang="scss" scoped>
.ModalReport {
  .modal-body-wrapper {
    fieldset,
    legend {
      margin-bottom: $ha-spacing-medium;
    }

    .FormGroup {
      padding-bottom: $ha-spacing-medium;

      &:has(
          input[type='radio']:checked,
          input[type='email']:valid,
          input[type='email']:user-valid,
          input[type='email']:placeholder-shown,
          textarea:placeholder-shown,
          textarea:user-valid,
          textarea:valid
        ),
        &:not(:checked) {
        .HaError {
          display: none;
        }
      }
    }

    .form-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
    }
  }

  &:not(:has(input[type='radio']:checked)),
  &:has(input[type='email']:invalid, textarea:invalid) {
    .submit-button {
      background-color: var(--ha-color-background-disabled);
      color: var(--ha-color-text-disabled);
      cursor: not-allowed;
    }
  }
}
</style>
