<template>
  <div class="Banner">
    <div
      class="Banner--Background"
      data-test-id="BannerBackground"
      :style="
        banner ? `background-image: url('${resizedBanner}')` : ''
      "
    >
      <span
        v-if="!banner"
        class="Pattern"
      />
      <template v-if="editMode">
        <div class="Banner--Action">
          <ha-button
            class="Banner--ActionUpload"
            color="basic"
            variant="outline"
            data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Banner_Add"
            @click="displayModalUpload = true"
          >
            <template #icon>
              <HaIcon :icon="faCamera" />
            </template>
            {{ $t('organization.headline.banner.update') }}
            <span class="visually-hidden">
              {{ $t('organization.headline.banner.updateLabel') }}
            </span>
          </ha-button>
          <button
            v-if="banner"
            class="Banner--ActionDelete"
            data-ux="Explore_OrganizationPublicPage_EditMode_Headline_Banner_Delete"
            @click="deleteBanner"
          >
            <HaIcon :icon="faTrash" />
            <span class="visually-hidden">
              {{ $t('organization.headline.banner.delete') }}
            </span>
          </button>
        </div>
      </template>
    </div>
    <div
      class="Banner--Background-Blur"
      :style="
        banner ? `background-image: url('${resizedBanner}')` : ''
      "
    >
      <span
        v-if="!banner"
        class="Pattern"
      />
    </div>
    <ModalUpload
      v-if="displayModalUpload"
      :media="banner"
      :ratio="3.83"
      :max-width="920"
      :max-height="240"
      :action="'updateBanner'"
      file-name="banner"
      @close="displayModalUpload = false"
    />
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { faCamera, faTrash } from '@fortawesome/pro-solid-svg-icons'
import {
  HaButton,
  HaIcon,
  useTracking,
  useNotifications
} from '@ha/components-v3'
import { resizedSrc } from '@/helpers/utils'

import ModalUpload from '@/components/Modal/ModalUpload.vue'
import useEditMode from '@/composables/useEditMode'
import { useI18n } from '#imports'

export default {
  name: 'Banner',
  components: {
    HaButton,
    HaIcon,
    ModalUpload
  },
  setup() {
    const { pushNotification } = useNotifications()
    const { tracking } = useTracking()
    const { editMode } = useEditMode()
    const i18n = useI18n()
    return { pushNotification, tracking, editMode }
  },
  data() {
    return {
      displayModalUpload: false,
      faCamera,
      faTrash
    }
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization
    },
    banner() {
      return this.organization?.banner
    },
    resizedBanner() {
      return resizedSrc(this.banner, {
        width: 920,
        height: 240
      })
    }
  },
  methods: {
    async deleteBanner() {
      try {
        await this.$store.dispatch(
          'organizations/deleteBanner',
          this.organization.organizationSlug
        )
        this.tracking.track('Organization Page Updated', {
          organization_slug: this.organization.organizationSlug,
          update_type: ['Retrait Bannière']
        })
        this.pushNotification({
          type: 'success',
          title: this.$t('toast.bannerDelete.title'),
          body: this.$t('toast.bannerDelete.body'),
          timeout: 5000
        })
      } catch (error) {
        this.pushNotification({
          type: 'danger',
          title: this.$t(
            `error.code.${get(error, 'response.status', 500)}.title`
          ),
          body: this.$t(
            `error.code.${get(error, 'response.status', 500)}.message`
          )
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/banner';
</style>
