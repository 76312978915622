<template>
  <HaModal
    class="ModalUpload"
    close-id="Explore_Modal_Upload_Close"
    v-bind="$attrs"
    @close="$emit('close')"
  >
    <template #header>
      {{
        media
          ? $t('modal.upload.update')
          : $t('modal.upload.choose')
      }}
      <small>
        {{ $t('modal.upload.suggestedSize') }} : {{ maxWidth }} x
        {{ maxHeight }}
      </small>
    </template>
    <template #body>
      <HaValidatorInput
        v-slot="{ errors }"
        :model-value="file"
        :rules="`required|file_type:${accept}|file_max_size:2000000`"
        name="file"
        slim
      >
        <HaFileUploader
          :file="file"
          :accept="accept"
          :max-size="fileSizeLimit"
          :formats="formats"
          :custom-preview-condition="customPreviewCondition"
          :data-ux="`Explore_OrganizationPublicPage_EditMode_Modal_Upload_${capitalize(
            fileName
          )}_Add`"
          @input="handleInput"
        >
          <template #preview="{ src }">
            <HaCropper
              ref="cropper"
              :src="src"
              :aspect-ratio="ratio"
              style="z-index: 1"
            />
            <br>
            <HaButton
              :loading="isUploading"
              :disabled="isInvalid || isUploading"
              :data-ux="`Explore_OrganizationPublicPage_EditMode_Modal_Upload${capitalize(
                fileName
              )}_Save`"
              @click="uploadMedia"
            >
              {{ $t('button.validate') }}
            </HaButton>
          </template>
        </HaFileUploader>
        <ha-message
          v-if="errors.length > 0"
          type="danger"
        >
          {{ errors[0] }}
        </ha-message>
      </HaValidatorInput>
    </template>
  </HaModal>
</template>

<script>
import {
  capitalize,
  startsWith,
} from 'lodash-es'
import {
  HaButton,
  HaModal,
  HaFileUploader,
  HaCropper,
  HaMessage,
  useNotifications,
  useTracking,  
  HaValidatorInput
} from '@ha/components-v3'
  import Compressor from 'compressorjs'

const FileType = {
  JPEG: 'image/jpeg',
  PNG: 'image/png'
}

const compress = async (file, options) => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      ...options,
      success: resolve,
      error: reject
    })
  })
}

export default {
  name: 'ModalUpload',
  components: {
    HaModal,
    HaButton,
    HaFileUploader,
    HaCropper,
    HaValidatorInput,
    HaMessage
  },
  props: {
    media: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    },
    fileSizeLimit: {
      type: String,
      default: '2 Mo'
    },
    ratio: {
      type: Number,
      default: 1
    },
    maxWidth: {
      type: Number,
      default: 300
    },
    maxHeight: {
      type: Number,
      default: 300
    }
  },
  emits: ['close'],
  setup(props) {
    const { pushNotification, notifyError } = useNotifications()
    const { tracking } = useTracking()

    const getFileExtention = file => {
      const fileSplit = file?.split('.')
      return fileSplit[fileSplit.length - 1].toLowerCase()
    }

    const getFileType = file => {
      const extension = getFileExtention(file)
      if (extension === 'jpeg' || extension === 'jpg') return FileType.JPEG
      else if (extension === 'png') return FileType.PNG
    }

    const file = props.media
    ? ref({
        name: `avatar.${getFileExtention(props.media)}`,
        src: props.media,
        type: getFileType(props.media)
      })
    : ref(null)
    return { pushNotification, notifyError, tracking, file }
  },
  data() {
    return {
      errorUpload: null,
      isUploading: false,
      formats: ['png', 'jpeg', 'jpg'],
      accept: [FileType.PNG, FileType.JPEG].join(','),
      isInvalid: false
    }
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization
    }
  },
  methods: {
    /**
     * Called by HaFileUploader to check if it should use the provided custom preview slot.
     * @param {String} fileType - MIME-type of the file
     */
    customPreviewCondition(fileType) {
      return startsWith(fileType, 'image/')
    },
    async uploadMedia() {
      if (this.file && !this.isUploading) {
        this.isUploading = true
        this.errorUpload = null

        const croppedFile = await this.$refs.cropper.getData()
        const blob = await compress(croppedFile, {
          maxWidth: this.maxWidth, // limit width
          maxHeight: this.maxHeight, // limit height
          quality: 1, // we try to conserve quality as much as possible
          convertSize: 2000000 // convert PNGs to JPEG if larger than 2Mo
        })
        this.file = new File([blob], blob.name, {
          type: blob.type
        })

        const errorHandling = (err) => {
          const status = err?.response.status
          this.errorUpload = this.$t(
            `error.code.${status}.message`
          )

          this.pushNotification({
            type: 'danger',
            title: this.$t('error.upload'),
            body: this.$t(`error.code.${status}.message`),
            timeout: 5000
          })
        }

        switch (this.action) {
          case 'updateBanner':
            await this.$store
              .dispatch(`organizations/${this.action}`, {
                slug: this.organization.organizationSlug,
                file: this.file
              })
              .catch(errorHandling)
            this.isUploading = false
            this.file = null
            this.tracking?.track('Organization Page Updated', {
              organization_slug: this.organization.organizationSlug,
              update_type: ['Ajout Bannière']
            })
            this.$emit('close')
            break
          case 'updateLogo':
            await this.$store
              .dispatch(`organizations/${this.action}`, {
                slug: this.organization.organizationSlug,
                file: this.file
              })
              .catch(errorHandling)
            this.isUploading = false
            this.file = null
            this.tracking?.track('Organization Page Updated', {
              organization_slug: this.organization.organizationSlug,
              update_type: ['Ajout Logo']
            })
            this.$emit('close')
            break
          case 'addImage':
            await this.$store
              .dispatch('organizations/setImage', {
                image: this.file
              })
              .catch(errorHandling)
            this.file = null
            this.isUploading = false
            this.$emit('close')
            break
        }
      }
    },
    capitalize(value) {
      return capitalize(value)
    },
    handleInput(file) {
      this.isInvalid = false

      if (file?.size && file.size > 10485760) {
        this.handleImageErrors('size')
        return
      } else if (file?.type && !file.type.includes('image')) {
        this.handleImageErrors('type')
        return
      }

      this.file = file
    },
    handleImageErrors(type) {
      this.notifyError(
        {},
        {
          type: 'danger',
          title: this.$t('errors.uploadAvatar.title'),
          body: this.$t(`errors.uploadAvatar.${type}`),
          timeout: 5000
        }
      )
      this.isInvalid = true
      this.file = null
    }
  }
}
</script>

<style lang="scss">
@import './styles/modal-upload';
</style>

<style lang="scss" scoped>
.Organization {
  h2 {
    small {
      display: block;
      margin-bottom: $ha-spacing-tiny;
      color: var(--ha-color-text-light);
      font-size: $ha-font-size-small;
    }
  }
}
</style>
